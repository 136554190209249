<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div
			class="w-100 px-3">
			<div class="d-flex flex-column align-items-center mb-1">
				<div class="h3 text-muted font-weight-bold text-center mb-1">
					{{ translate(promoCodeName) }}
					<a
						v-if="link"
						v-b-tooltip.hover
						:href="link"
						target="_blank"
						:title="translate('view_details')"
						class="text-decoration-none link-primary text-small text-muted">
						<i class="fas fa-info-circle" />
					</a>
				</div>
				<div
					class="h3 font-weight-bold text-center mb-2"
					:class="statusColor">
					{{ translate(`${promoCodeName}_${status}`) }}
				</div>
				<p
					class="text-center"
					v-html="description" />
			</div>
			<b-col
				v-if="showActionButton"
				class="align-content-center text-center">
				<b-button
					variant="primary"
					class="mt-2 px-4"
					@click="activateMembership">
					{{ translate(`${promoCodeName}_${status}_button`) }}
					<!--<em
						v-if="loadingSync"
						class="fas fa-sync"
						:class="loadingSync ? 'fa-spin' : ''" />-->
				</b-button>
			</b-col>
		</div>
	</div>
</template>
<script>

import WindowSizes from '@/mixins/WindowSizes';
import Promotions from '@/util/Promotions';
import { Promotions as messages, Tooltip } from '@/translations';
import { S3_PATH } from '@/settings/Images';
import { MDY_FORMAT } from '@/settings/Dates';

export default {
	name: 'Membership',
	messages: [messages, Tooltip],
	mixins: [WindowSizes],
	props: {
		promoCodeName: {
			type: String,
			default: null,
		},
		data: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			promotionsInfo: new Promotions(),
			S3_PATH,
			theme: process.env.VUE_APP_THEME,
			loadingSync: false,
			dateFormat: MDY_FORMAT,
		};
	},
	computed: {
		description() {
			try {
				return this.translate(`${this.promoCodeName}_${this.status}_description`, { next_payment_date: this.paymentDate });
			} catch (e) {
				return '';
			}
		},
		status() {
			try {
				return this.data.status;
			} catch (e) {
				return '';
			}
		},
		paymentDate() {
			try {
				const { date } = this.data.process_date;
				return this.$moment(date).format(this.dateFormat);
			} catch (e) {
				return '';
			}
		},
		statusColor() {
			try {
				return this.status === 'active' ? 'text-light-green' : 'text-danger';
			} catch (e) {
				return '';
			}
		},
		showActionButton() {
			try {
				return this.status !== 'active';
			} catch (e) {
				return false;
			}
		},
		link() {
			try {
				return this.data.link;
			} catch (e) {
				return false;
			}
		},
	},
	methods: {
		activateMembership() {
			this.$router.push({ name: 'Renewal', query: { membership: 'v_club' } });
		},
	},
};
</script>
